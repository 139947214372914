import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import bannerProduct from "../../../assets/images/banner-all-product.png";
import aboutUsImage from "../../../assets/images/about-us.png";
import mission from "../../../assets/images/mission.png";
import Divider from "@material-ui/core/Divider";
import Title from "../../../components/Title";
import Text from "../../../components/Text";
import ReactHtmlParser from 'react-html-parser'
import {
  fetchBrand,
  fetchHomeBannerSlider,
  fetchProductData,
  getAllPosts,
  getBodListData
} from "../../../redux/actions/home";
import {connect} from "react-redux";
import Popup from "../../../components/Modal";

const AboutUs = (props) => {
  const {posts,getBodList,bod_list} = props

  const main = posts.find(item => item.post_name === 'our-story')
  const details = posts.find(item => item.post_name === 'who-we-are')
  const mission = posts.find(item => item.post_name === 'mission-and-vision')
  //console.log('bod list',bod_list);
  const [popUpOpen,setPopUpOpen] = useState(false);
  const [person,setPerson] = useState(null);
  const changePopupValue = (value)=>{
    setPopUpOpen(value);
  }

  const setPersonValue = (person)=>{
    setPerson(person);
    changePopupValue(true);
  }

  useEffect(()=>{
    getBodList();
  },[])

  return (
      <StyledAboutUs>
        <Container className="Banner-view">
          <Row className="change-view">
            <Col>
              <div style={{display: 'flex' , marginTop: 90, alignItems: 'center'}}>
                <div className="text-breadcrumb">Shanta Lifestyle</div>
                <div className="text-breadcrumb-point"/>
                <div className="text-breadcrumb">About Us</div>
              </div>
              <Divider style={{marginTop: 6, marginBottom: 30}}/>
            </Col>
          </Row>
          <Row>
            <Col>
              {/*<div className="subTitle">*/}
              {/*  About us*/}
              {/*</div>*/}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="about-page">
                <div className="top-title">
                  About us
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="column-cl">
              <Container>
                <div className="client-single">
                  <div className="client-single__inner">
                    <img
                        src={'https://api.shantalifestyle.com/wp-content/uploads/2022/05/New-Project-6-4.png'}
                        alt="Our story banner image"
                    />
                  </div>
                </div>
              </Container>
            </Col>
          </Row>
          <Divider style={{marginTop: 30, marginBottom: 30}}/>
          <Title
              text={main ? 'Vision: '+main.post_title : ''}
              fontFamily={'Renner,sans-serif'}
              fontSize={18}
              fontWeight={'700'}
              color={'#313132'}
          />
          <Text
              fontSize={14}
              fontFamily={'Poppins'}
              fontWeight={'normal'}
              margin={'30px 0 0 0'}
          >
            <p style={{fontSize: 14}}>{main ? ReactHtmlParser(main.post_content) : ''}</p>
          </Text>

        </Container>

        <Container className="body-view">
          <Divider style={{marginTop: 55, marginBottom: 55}}/>
          <Row>
            <Col lg={4} xs={12} className="content-left-view">

              {/*<div className="change-view-text">*/}
              {/*  <div className="subTitle-text">*/}
              {/*    About us*/}
              {/*  </div>*/}
              {/*  <div style={{display: 'flex', alignItems: 'center', marginBottom: 32}}>*/}
              {/*    <div className="top-title-text">*/}
              {/*      Vision*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div className="division-content">
                <div className="division-content__img">
                  <img
                      src={details ? details.post_feature_image : ''}
                      alt="Who we are image"
                  />
                </div>
              </div>
            </Col>
            <Col lg={8} xs={12} className="content-right-view">
              {/*<div className="change-view-text-two">*/}
                {/*<div className="subTitle-text">*/}
                {/*  About us*/}
                {/*</div>*/}
                {/*<div style={{display: 'flex', alignItems: 'center', marginBottom: 32}}>*/}
                {/*  <div className="top-title-text">*/}
                {/*    Vision*/}
                {/*  </div>*/}
                {/*</div>*/}
              {/*</div>*/}
              <div style={{display: 'flex', alignItems: 'center', marginBottom: 24}}>
                <div className="top-title-text-bold">
                  {details ? details.post_title : ''}
                </div>
              </div>
              <div style={{display: 'flex', alignItems: 'center', marginBottom: 24}}>
                <div className="top-title-text-body">
                  {details ? ReactHtmlParser(details.post_content) : ''}
                </div>
              </div>
              {/*<div className="button-load">*/}
              {/*  <div className="read-more">*/}
              {/*    <div className="load-button">*/}
              {/*      Read more*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </Col>
          </Row>
        </Container>

        <Container className="body-view">
          <Divider style={{marginTop: 55, marginBottom: 55}}/>
          <Row>
            <Col lg={4} xs={12} className="content-left-view">
              <div className="change-view-text">
                {/*<div className="subTitle-text">*/}
                {/*  About us*/}
                {/*</div>*/}
                <div style={{display: 'flex', alignItems: 'center', marginBottom: 32}}>
                  <div className="top-title-text">
                    Mission
                  </div>
                </div>
              </div>
              <div className="division-content">
                <div className="division-content__img">
                  <img
                      src={mission ? mission.post_feature_image : ''}
                      alt="Mission & vision image"
                  />
                </div>
              </div>
            </Col>
            <Col lg={8} xs={12} className="content-right-view">
              <div className="change-view-text-two">
                {/*<div className="subTitle-text">*/}
                {/*  About us*/}
                {/*</div>*/}
                <div style={{display: 'flex', alignItems: 'center', marginBottom: 32}}>
                  <div className="top-title-text">
                    Mission
                  </div>
                </div>
              </div>
              <div style={{display: 'flex', alignItems: 'center', marginBottom: 24}}>
                {/*<div className="top-title-text-bold">*/}
                {/*  {mission ? mission.post_title : ''}*/}
                {/*</div>*/}
              </div>
              <div style={{display: 'flex', alignItems: 'center', marginBottom: 24}}>
                <div className="top-title-text-body">
                  {mission ? ReactHtmlParser(mission.post_content) : ''}
                </div>
              </div>
              {/*<div className="button-load">*/}
              {/*  <div className="read-more">*/}
              {/*    <div className="load-button">*/}
              {/*      Read more*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </Col>
          </Row>
        </Container>

        <Container className="Banner-view">
          <Divider style={{marginTop: 30, marginBottom: 30}}/>
          <Row>
            <Col>
              <div style={{display: 'flex', alignItems: 'center', marginBottom: 24}}>
                <div className="top-title">
                  Senior Management
                </div>
              </div>
            </Col>
          </Row>

          <StyledBod>
            {bod_list.length> 0 &&
            bod_list.map((person, key)=>{
              return (
                  <Col key={key} lg={3} xs={12} sm={6} style={{paddingLeft:"0",marginBottom: "15px"}}>
                    <Col lg={12} xs={12} className="content-left-view">
                      <div className="division-content" onClick={()=>setPersonValue(person)} style={{cursor: "pointer"}}>
                        <div className="division-content__img">
                          <img
                              width={'100%'}
                              src={person.post_feature_image ? person.post_feature_image : ''}
                              alt={`${person.post_title}`}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col lg={12} xs={12} className="content-right-view">
                      <div className="change-view-text-two">
                        <div onClick={()=>setPersonValue(person)}
                             style={{display: 'flex', alignItems: 'center',marginTop:"8px",cursor:"pointer"}}>
                          <div
                              className="top-title-text" style={{textTransform: "unset",fontSize: "20px",paddingBottom:"5px"}}>
                            {person.post_title}
                          </div>
                        </div>
                        <div className="top-subTitle-text" style={{fontSize:"14px"}}>
                          {person.post_excerpt}
                        </div>
                      </div>
                    </Col>
                  </Col>
              )
            })
            }
          </StyledBod>
          {person !== null && <Popup show={popUpOpen} handleClose={()=> changePopupValue(false)} title={person?.post_title} post={person.post_content}/>}
        </Container>


      </StyledAboutUs>
  )
}

const StyledBod = styled(Row)`

`;
const StyledAboutUs = styled.div`
  padding-bottom: 64px;

  .container {
    max-width: 95%;
    @media(max-width: 991px){
      max-width: 100%;
      padding: 25px;
    }
  }

  .Banner-view {
    display: block;
  }
  
  .about-page {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    @media(max-width: 991px){
      margin-top: 100px;
    }
  }

  .text-breadcrumb {
    font-weight: normal;
    font-size: 10px;
    color: #313132;
    font-family: "Poppins", sans-serif;
  }

  .text-breadcrumb-point{
    width: 3px;
    height: 3px;
    color: #313132;
    border-radius: 50%;
    background-color: #313132;
    margin-right: 8px;
    margin-left: 8px;
  }

  .body-view {
    display: block;

    .container {
      max-width: 100%;
      padding: 0px;
    }

    .content-left-view {
      position: relative;
    }

    .content-right-view {
      position: relative;
      @media(max-width: 991px){
       margin-top: 140px;
      }
    }
    
    .change-view-text{
      display: none;
      @media(max-width: 991px){
        display: block;
      }
    }

    .change-view-text-two{
      display: block;
      @media(max-width: 991px){
        display: none;
      }
    }

    .division-content {
      height: 100%;

      &__img {
        padding-top: 93.58%;
        position: relative;
        height: 100%;
        @media(max-width: 991px){
          padding-top: 80.58%;
        }

        img {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
        }
      }
    }

    .button-load {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 0px;
      @media(max-width: 991px){
        bottom: -24px;
      }
    }
  }

  .change-view {
    display: block;
    @media(max-width: 991px){
      display: none;
    }
  }

  .subTitle {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 2px;
    margin-top: 10px;
    color: rgb(156, 148, 52);
    @media(max-width: 991px){
      margin-top: 120px;
    }
  }

  .top-title {
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 8px;
    color: #313132;
  }

  .subTitle-text {
    font-family: Renner, sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 2px;
    color: rgb(156, 148, 52);
  }

  .top-title-text {
    font-size: 24px;
    font-weight: 500;
    font-family: Renner,sans-serif;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 8px;
    color: #313132;
  }

  .top-title-text-bold {
    font-size: 18px;
    font-weight: bold;
    font-family: Renner,sans-serif;
    padding-left: 0px;
    padding-bottom: 8px;
    color: #313132;
  }

  .top-title-text-body {
    font-size: 14px;
    font-weight: normal;
    font-family: 'Poppins', sans-serif;
    padding-left: 0px;
    padding-bottom: 8px;
    color: #313132;
    padding-right: 45px
  }

  .column-cl {
    margin-bottom: 30px;

    .container {
      max-width: 100%;
    }
  }

  .read-more {
    background-color: #fff;
    border-radius: 50px;
    border: 1px solid #313132;
    cursor: pointer;
    text-align: center;
    width: 78px;
    height: 25px;

    .load-button {
      color: #313132;
      font-size: 10px;
      font-weight: normal;
      font-family: 'Poppins', sans-serif;
      padding: 4px 8px 3px 8px;
    }
    &:hover{
      border: 1px solid #9e6c51;
      background-color: #9e6c51;
      .load-button {
        color: #ffffff;
      }
    }
  }

  .client-single {
    &__inner {
      padding-top: 56.25%;
      border: 0 solid #707070;

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 0;
        object-fit: cover;
        width: 98.3%;
      }
    }
  }

  @media (max-width: 991px) {
    .col-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .Banner-view {
      display: block;
    }

    .body-view {
      display: block;
    }

    .MuiLinearProgress-root {
      display: block;
    }

    .texts {
      display: block;

      p {
        font-size: 10px;
      }
    }

    .read-more {
      background-color: #fff;
      border-radius: 50px;
      border: 1px solid #313132;
      cursor: pointer;
      text-align: center;
      width: 100%;

      .load-button {
        color: #313132;
        font-size: 10px;
        font-weight: normal;
        font-family: 'Poppins', sans-serif;
      }
    }
  }
`

function mapStateToProps(state) {
  return {
    bod_list: state.homeReducer.bod_list,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getBodList: () => dispatch(getBodListData())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
