import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { hover, darkGreen } from "../globalStyleVars";
import mainLogo from "../assets/images/logo.svg";
import hambuergerWhite from "../assets/images/close.svg";
import { Link, useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "../assets/icons/menu.svg";
import SearchBar from "./SearchBar";
import SearchBarMobile from "./SearchBarMobile";
import Heart from "../assets/icons/heart.svg";
import Cart from "../assets/icons/shopping-bag.svg";
import Truck from "../assets/icons/truck.svg";
import User from "../assets/icons/user.svg";
import { connect } from "react-redux";
import { handleAuthModal, getUserData } from "../redux/actions/auth";
import {
  handleRightDrawer,
  fetchAllCart,
  getAllWishlist,
} from "../redux/actions/rightDrawer";
import { openLeftDrawer } from "../redux/actions/left-drawer";
import {
  fetchCategoryData,
  getAllPosts,
  fetchProductData,
  fetchPages,
  fetchHomeBannerSlider, fetchBrand,
} from "../redux/actions/home";
import { getAllOrders } from "../redux/actions/orders";

import Badge from "@material-ui/core/Badge";
import $ from "jquery";

const Menu = ({
  logo,
  color,
  handleAuthModal,
  handleRightDrawer,
  cart,
  wishlist,
  authData,
  fetchCategoryData,
  handleLeftDrawer,
  getAllPosts,
  getAllOrders,
  fetchProductData,
  allCart,
  getUserData,
  categories,
  allProducts,
  allWishlist,
  posts,
  fetchPages,
  pages,fetchBrand,
  fetchAllCart,
  getAllWishlist,
}) => {
  let [menuItems, setMenuItems] = useState(false);
  let [newClass, setNewClass] = useState("");
  let [mobileSlide, setMobileSlide] = useState("");

  let isAuth = authData ? (authData.token ? authData.token : null) : null;

  let activeCart = isAuth ? allCart : cart;

  let activeWishList = isAuth ? allWishlist : wishlist;

  let handleMenuToggle = (e) => {
    e.stopPropagation();
    setMenuItems(!menuItems);
  };

  let handleClose = () => {
    setMenuItems(false);
  };

  let history = useHistory();

  useEffect(() => {
    if (authData) {
      if (authData.token) {
        let id = {
          customer: authData.id,
        };
        let userData = {
          user_id: authData.id,
        };
        fetchAllCart(userData);
        getAllWishlist(userData);
        getAllOrders(id);
        getUserData(userData);
      }
    }
  }, [authData]);

  useEffect(() => {
    //fetchHomeBannerSlider();
    //fetchBrand()
    if (categories) {
      if (!categories.length) {
        fetchCategoryData();
      }
    }

    // if (posts) {
    //   if (!posts.length) {
    //     getAllPosts();
    //   }
    // }

    // if (pages) {
    //   if (!pages.length) {
    //     fetchPages();
    //   }
    // }

    // let data = {
    //   id: 3
    // }
    // fetchPages(data)

    // if (allProducts) {
    //   if (!allProducts.length) {
    //     fetchProductData();
    //   }
    // }

    window.addEventListener("scroll", function () {
      if (window.pageYOffset > 100) {
        setNewClass("menu-fixed");
      } else {
        setNewClass("");
      }
    });

    // menu click on mobile
    $(".mobile-menu .menuItems__top__single").each(function () {
      let $this = $(this);
      if ($this.find("ul").length > 0) {
        $this.addClass("hasChild");
      }
    });

    $(".hasChild").on("click", function () {
      var $this_ul = $(this).find("ul"),
          $this = $(this);
      $(".hasChild ul").not($this_ul).slideUp();
      $(".hasChild").not($this).removeClass("active");
      $this_ul.slideToggle();
      $this.toggleClass("active");
    });

    $(".mobile-menu .menuItems__top__single li a").click(function () {
      $(".menu-items").removeClass("true");
      $(".menu-bar").removeClass("true");
    });
  }, []);

  let handleModal = (data) => {
    handleAuthModal(data);
  };

  return (
    <div>
      <StyledMenu className={`menu-bar ${newClass} ${menuItems}`} color={color}>
        <Container>
          <Row>
            <Col md={12} className="menu-bar__menu-lists">
              <ul onClick={handleClose}>
                <li>
                  <div
                    className="menu-button"
                    onClick={() => handleLeftDrawer("left-main")}
                  >
                    <img src={MenuIcon} alt="" />
                  </div>
                </li>
                <li>
                  <Col className="menu-bar__logo">
                    <a style={{ paddingLeft: 0,cursor: "pointer" }} onClick={() => history.push("/")}>
                      <img src={logo || mainLogo} alt="" />
                    </a>{" "}
                  </Col>
                </li>
                <li>
                  <Link onClick={() => handleLeftDrawer("left-products")}>
                    View By Product
                  </Link>
                </li>
               
                <li>
                  <Link onClick={() => handleLeftDrawer("left-room")}>
                    View By Room
                  </Link>
                </li>
                <li>
                  <Link onClick={() => handleLeftDrawer("left-brand")}>
                    View By Brand
                  </Link>
                </li>
                <li>
                  <Link onClick={() => history.push("/scope-of-work")}>
                    Scope of Work
                  </Link>
                </li>
                <li style={{ display: "flex" }}>
                  <div
                    className="menu-button-last"
                    onClick={() => handleLeftDrawer("left-main")}
                  >
                    <img src={MenuIcon} alt="" />
                  </div>
                  <div style={{ marginLeft: 20 }}>
                    <a style={{cursor: "pointer" }} onClick={() => history.push("/")}>
                      <img src={logo || mainLogo} alt="" />
                    </a>{" "}
                  </div>
                </li>
              </ul>
              <ul onClick={handleClose} className="search-ul">
                <li className="desktop-search">
                  <SearchBar />
                </li>
                {/*<li style={{ marginRight: 0 }}>*/}
                {/*<IconButton*/}
                {/*    aria-label="menu"*/}
                {/*    onClick={() => handleRightDrawer("wishlist")}*/}
                {/*  >*/}
                {/*    <Badge*/}
                {/*      badgeContent={activeWishList ? activeWishList.length : 0}*/}
                {/*      color="primary"*/}
                {/*    >*/}
                {/*      <img*/}
                {/*        src={Heart}*/}
                {/*        alt="heart"*/}
                {/*        style={{ width: 16, height: 16, objectFit: "fill" }}*/}
                {/*      />*/}
                {/*    </Badge>*/}
                {/*  </IconButton>*/}



                  {/*<IconButton*/}
                  {/*  aria-label="menu"*/}
                  {/*  onClick={() => handleRightDrawer("cart")}*/}
                  {/*>*/}
                  {/*  <Badge*/}
                  {/*    badgeContent={activeCart ? activeCart.length : 0}*/}
                  {/*    color="primary"*/}
                  {/*  >*/}
                  {/*    <img*/}
                  {/*      src={Cart}*/}
                  {/*      alt="cart"*/}
                  {/*      style={{ width: 16, height: 16, objectFit: "fill" }}*/}
                  {/*    />*/}
                  {/*  </Badge>*/}
                  {/*</IconButton>*/}

                  {/*{isAuth ?*/}
                  {/*    <IconButton*/}

                  {/*        aria-label="menu"*/}
                  {/*        onClick={() => history.push("/orders")}*/}
                  {/*        className="truck"*/}
                  {/*    >*/}
                  {/*      <img*/}
                  {/*          src={Truck}*/}
                  {/*          alt="truck"*/}
                  {/*          style={{width: 16, height: 16, objectFit: "fill"}}*/}
                  {/*      />*/}
                  {/*    </IconButton>*/}
                  {/*: null}*/}

                  {/*<IconButton*/}
                  {/*  aria-label="menu"*/}
                  {/*  onClick={*/}
                  {/*    isAuth*/}
                  {/*      ? () => handleModal("auth-modal")*/}
                  {/*      : () => handleModal("login")*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  <img*/}
                  {/*    src={User}*/}
                  {/*    alt="user"*/}
                  {/*    style={{ width: 16, height: 16, objectFit: "fill" }}*/}
                  {/*  />*/}
                  {/*</IconButton>*/}
                {/*</li>*/}
              </ul>
            </Col>
          </Row>
          <Row className="search-mobile">
            <Col>
              <SearchBarMobile />
            </Col>
          </Row>
        </Container>
      </StyledMenu>
    </div>
  );
};

const StyledMenu = styled.div`
  position: fixed;
  display: block;
  z-index: 9;
  width: 100%;
  top: 0;
  height: 90px;
  align-items: center;
  display: flex;
  background-color: white;
  transition: background-color 0.3s ease;

  .search-mobile {
    display: none;
  }

  .menu-button {
    cursor: pointer;
  }

  .menu-button-last {
    display: none;
  }

  .container {
    max-width: 95%;
  }

  .row {
    align-items: center;
  }

  &.menu-fixed {
    //background-color: ${hover};

    .col-md-12 {
      //padding-right: 0;
      //padding-left: 0;
    }

    .menu-bar__menu-lists {
      ul {
        li {
          a {
            &:hover,
            &.active {
              color: ${darkGreen};
            }
          }
        }
      }
    }
  }

  &.true {
    background-color: transparent;
  }

  .menu-bar__logo {
    img {
      height: 30px; // Previous value was 40px
      object-fit: contain;
    }
  }

  .menu-bar__menu-lists {
    display: flex;
    align-items: center;

    .search-ul {
      position: absolute;
      right: 0;
      width: 40%;
      justify-content: flex-end;

      .desktop-search {
        width: 70%;
        @media (max-width: 1500px) {
          width: 60%;
        }
        @media (max-width: 1384px) {
          width: 50%;
        }
        @media (max-width: 1280px) {
          width: 36%;
        }
      }
    }

    ul {
      display: flex;
      align-items: center;

      li {
        width: fit-content;
        margin-right: 25px;
        @media (max-width: 1105px) {
          margin-right: 24px;
        }
        @media (max-width: 991px) {
          margin-right: 24px;
          margin-left: -5px;
        }

        a {
          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
          font-family: Renner, sans-serif;
          text-transform: capitalize;
          color: ${(props) => props.color || "#000000"};
          transition: color 0.3s ease;
          padding-left: 30px;
          @media (max-width: 1280px) {
            padding-left: 0px !important;
          }
          &:hover,
          &.active {
            color: #000000;
          }
        }

        &:nth-last-of-type(1) a {
          transition: opacity 0.4s ease;
          transition-delay: 0s;

          &:hover {
            opacity: 0.4 !important;
          }
        }

        &:nth-last-of-type(1) a {
          img:nth-last-of-type(1) {
            display: none;
            height: 22px;
          }
        }
      }
    }
  }

  &.true {
    li:not(:last-child) a {
      opacity: 0 !important;
      visibility: hidden !important;
    }

    li:nth-last-of-type(1) a img {
      &:nth-of-type(1) {
        display: none;
      }

      &:nth-of-type(2) {
        display: block !important;
      }
    }
  }

  @media (max-width: 991px) {
    .container {
      max-width: 100%;
      padding-left: 25px;
      padding-right: 25px;
    }

    height: 120px;
    .menu-bar__menu-lists ul li:nth-last-of-type(1) a img:nth-last-of-type(1) {
      display: block;
    }

    .search-mobile {
      display: block;
    }

    .truck {
      display: none;
    }

    .menu-button-last {
      display: block;
    }

    .menu-bar__menu-lists {
      ul {
        justify-content: flex-end;

        li:not(:last-child) {
          display: none;
        }
      }
    }
  }

  @media (max-width: 768px) {
    height: 120px;
    .container {
      overflow: unset !important;
    }

    .menu-bar__logo img {
      height: 45px;
    }
  }
`;

const StyleToggleMenu = styled.div`
  position: fixed;
  z-index: 8;
  height: 100vh;
  width: 100%;
  background: ${hover};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s ease;

  &.true {
    opacity: 1;
    visibility: visible;
  }

  .menuItems__top {
    height: calc(100vh - 130px);
    display: flex;
    padding: 80px;
    position: relative;
    //background:  #0366C2;
    margin-top: 90px;
    max-height: 600px;

    &__bg {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      height: 100%;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.8);
        right: 0;
        bottom: 0;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    &__single {
      position: relative;
      z-index: 3;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      height: max-content;
      flex-direction: column;
      padding-right: 8px;

      > a {
        font-size: 25px;
        font-weight: 500;
        color: #ffffff;
        text-transform: capitalize;
        line-height: 30px;
        margin-bottom: 26px;
        transition: letter-spacing 0.6s ease;

        &:hover {
          letter-spacing: 2px;
        }
      }

      ul {
        text-align: left;

        li {
          a {
            display: block;
            font-size: 16px;
            text-transform: capitalize;
            color: #ffffff;
            margin-bottom: 18px;
            transition: padding 0.4s ease;

            &:hover {
              padding-left: 10px;
            }
          }

          &:nth-last-of-type(1) a {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  @media (min-width: 1440px) {
    @media (min-height: 800px) {
      display: flex;
      align-items: center;
      .menuItems__top {
        padding: 100px;
        margin-top: 0;
      }
    }
  }

  @media (min-width: 768px) {
    .mobile-menu {
      display: none;
    }
  }

  @media (max-width: 767px) {
    height: 100vh;
    padding-top: 30px;
    .menuItems__top {
      height: auto;
      display: block;
      padding: 0px 0px 0 0px;
      position: relative;
      min-height: 100vh;
      background-color: transparent;

      &__bg {
        display: none !important;
      }

      &__single {
        display: block;
        width: 100%;

        > a {
          font-size: 15px;
          display: block;
          margin-bottom: 15px;
        }

        ul {
          display: none;
        }
      }
    }

    .Footer__social {
      margin-top: 50px;
    }

    .desktop-menu {
      display: none;
    }

    .mobile-menu {
      .menuItems__top__single.active > a {
        color: #ffffff;
        opacity: 0.7;
      }
    }
  }

  .menuItems__top__single {
    position: relative;

    &:after {
      content: "";
      background-image: url(${hambuergerWhite});
      background-size: 13px;
      position: absolute;
      right: -17px;
      height: 50px;
      width: 50px;
      top: -12px;
      background-repeat: no-repeat;
      background-position: center;
      transform: rotate(45deg);
      margin: auto;
      z-index: 3;
      display: none;
      transition: all 0.4s ease;
    }

    ul {
      margin-bottom: 30px;
    }

    &.hasChild {
      &:after {
        display: block;
      }
    }

    &.active {
      &:after {
        transform: none;
      }

      > a {
        color: ${hover};

        ul li a {
          color: #d4d4d4 !important;
        }
      }
    }
  }
`;

function mapStateToProps(state) {
  return {
    cart: state.rightDrawerReducer.cart,
    allCart: state.rightDrawerReducer.allCart,
    wishlist: state.rightDrawerReducer.wishlist,
    authData: state.authReducer.authData,
    categories: state.homeReducer.categories,
    allProducts: state.homeReducer.allProducts,
    allWishlist: state.rightDrawerReducer.allWishlist,
    posts: state.homeReducer.posts,
    pages: state.homeReducer.pages,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleAuthModal: (data) => dispatch(handleAuthModal(data)),
    handleRightDrawer: (data) => dispatch(handleRightDrawer(data)),
    fetchCategoryData: (data) => dispatch(fetchCategoryData()),
    handleLeftDrawer: (data) => dispatch(openLeftDrawer(data)),
    // getAllPosts: () => dispatch(getAllPosts()),
    getAllOrders: (data) => dispatch(getAllOrders(data)),
    getUserData: (data) => dispatch(getUserData(data)),
    // fetchProductData: () => dispatch(fetchProductData()),
    //fetchPages: (data) => dispatch(fetchPages(data)),
    // fetchHomeBannerSlider: () => dispatch(fetchHomeBannerSlider()),
    fetchAllCart: (data) => dispatch(fetchAllCart(data)),
    getAllWishlist: (data) => dispatch(getAllWishlist(data)),
    fetchBrand: () => dispatch(fetchBrand())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
